define("discourse/plugins/discourse-unhandled-tagger/connectors/topic-footer-main-buttons-before-create/handled-button", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse/models/topic", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _ajaxError, _topic, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HandledButton extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        topic
      } = _ref;
      let {
        siteSettings,
        currentUser
      } = _ref2;
      return currentUser?.staff && !topic.isPrivateMessage && siteSettings.tagging_enabled && siteSettings.unhandled_tag;
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get topic() {
      return this.args.outletArgs.topic;
    }
    get handled() {
      return !this.topic.get("tags")?.includes?.(this.siteSettings.unhandled_tag);
    }
    async setUnhandled(value) {
      const tags = this.topic.tags;
      tags.removeObject(this.siteSettings.unhandled_tag);
      if (value) {
        tags.addObject(this.siteSettings.unhandled_tag);
      }
      try {
        await _topic.default.update(this.topic, {
          tags
        });
        this.appEvents.trigger("header:show-topic", this.topic);
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "setUnhandled", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.handled}}
          <DButton
            class="unhandle"
            @icon="circle-xmark"
            @action={{fn this.setUnhandled true}}
            @label="unhandled_tagger.unhandle.title"
            @title="unhandled_tagger.unhandle.help"
          />
        {{else}}
          <DButton
            class="handle"
            @icon="circle-check"
            @action={{fn this.setUnhandled false}}
            @label="unhandled_tagger.handled.title"
            @title="unhandled_tagger.handled.help"
          />
        {{/if}}
      
    */
    {
      "id": "gPmHCGSt",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"handled\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"unhandle\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\"],[\"circle-xmark\",[28,[32,1],[[30,0,[\"setUnhandled\"]],true],null],\"unhandled_tagger.unhandle.title\",\"unhandled_tagger.unhandle.help\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,0],[[24,0,\"handle\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\"],[\"circle-check\",[28,[32,1],[[30,0,[\"setUnhandled\"]],false],null],\"unhandled_tagger.handled.title\",\"unhandled_tagger.handled.help\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-unhandled-tagger/connectors/topic-footer-main-buttons-before-create/handled-button.js",
      "scope": () => [_dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HandledButton;
});